<template>
  <div>
    <a
        href="#"
        class="btn btn-icon btn-clean btn-lg mb-1 position-relative"
        id="kt_quick_panel_toggle"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        title=""
        data-original-title="Quick Panel"
    >
      <span class="svg-icon svg-icon-xl">
        <!--begin::Svg Icon-->
        <!--        <inline-svg src="/media/svg/icons/Layout/Layout-4-blocks.svg" />-->
        <i class="fas fa-bell"></i>
        <!--end::Svg Icon-->
      </span>
      <span
          class="label label-sm label-light-danger label-rounded font-weight-bolder position-absolute top-0 right-0 mt-1 mr-1">
        {{ notifications ? notifications.length : '' }}
      </span>
    </a>

    <!-- begin::Quick Panel -->
    <div
        id="kt_quick_panel"
        ref="kt_quick_panel"
        class="offcanvas offcanvas-left pt-5 pb-10"
        style="overflow: hidden;"
    >
      <!--begin::Header-->
      <div
          class="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5"
      >
        <ul
            class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
            role="tablist">
          <li class="nav-item">
            <a
                class="nav-link disabled-link"
                v-on:click="setActiveTab"
                data-tab="1"
                data-toggle="tab"
                href="#"
                role="tab"
                aria-selected="false">
              Notifications
            </a>
          </li>
          <li class="nav-item">
            <a
                class="nav-link disabled-link"
                v-on:click="setActiveTab"
                data-tab="2"
                data-toggle="tab"
                href="#"
                role="tab"
                aria-selected="false"
                aria-disabled="false">
              Settings
            </a>
          </li>
        </ul>
        <div class="offcanvas-close mt-n1 pr-5">
          <a
              href="#"
              class="btn btn-xs btn-icon btn-light btn-hover-primary"
              id="kt_quick_panel_close"
          >
            <i class="ki ki-close icon-xs text-muted"></i>
          </a>
        </div>
      </div>
      <!--end::Header-->

      <div class="offcanvas-content px-10">
        <div class="tab-content">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <div class="scroll pr-7 mr-n7" id="kt_quick_panel_logs">
                <div class="mb-15">
                  <div class="d-flex align-items-center justify-content-between">
                    <h5 class="font-weight-bold mb-5">Notifications</h5>
                    <router-link class="font-weight-bold mb-5 btn btn-info" to="/backoffice/notification/list">View all</router-link>
                  </div>
                  <template v-for="(item, i) in notifications">
                    <!--begin: Item -->
                    <div
                        class="d-flex align-items-center rounded p-5 mb-5 bg-light-info"
                        v-bind:key="i">

                      <div class="d-flex flex-column flex-grow-1 mr-2">
                        <a
                            href="#"
                            class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                            v-html="item.data.data">

                        </a>
                        <span class="font-size-sm">
                         Date :  {{ item.created_at | moment("ddd, MMM Do YYYY, h:mm:ss a") }}
                        </span>
                      </div>

                    </div>
                    <!--end: Item -->
                  </template>
                </div>
              </div>
            </b-tab>

            <b-tab>
              <div class="scroll pr-7 mr-n7" id="kt_quick_panel_notifications">
                <!--begin::Nav-->
                <div class="navi navi-icon-circle navi-spacer-x-0">
                  <template v-for="(item, i) in list3">
                    <!--begin::Item-->
                    <a href="#" class="navi-item" v-bind:key="i">
                      <div class="navi-link rounded">
                        <div class="symbol symbol-50 mr-3">
                          <div class="symbol-label">
                            <i class="icon-lg" v-bind:class="item.icon"/>
                          </div>
                        </div>
                        <div class="navi-text">
                          <div class="font-weight-bold font-size-lg">
                            {{ item.title }}
                          </div>
                          <div class="text-muted">
                            {{ item.desc }}
                          </div>
                        </div>
                      </div>
                    </a>

                    <div class="mb-15">
                      <h5 class="font-weight-bold mb-5">Notifications</h5>
                      <template v-for="(item, i) in list2">
                        <!--begin: Item -->
                        <div
                            class="d-flex align-items-center rounded p-5 mb-5"
                            v-bind:key="i"
                            v-bind:class="`bg-light-${item.type}`"
                        >
                      <span
                          class="svg-icon mr-5"
                          v-bind:class="`svg-icon-${item.type}`"
                      >
                        <inline-svg
                            :src="item.svg"
                            class="h-50 align-self-center"
                        />
                      </span>

                          <div class="d-flex flex-column flex-grow-1 mr-2">
                            <a
                                href="#"
                                class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                            >
                              {{ item.title }}
                            </a>
                            <span class="text-muted font-size-sm">
                          {{ item.desc }}
                        </span>
                          </div>

                          <span
                              class="font-weight-bolder py-1 font-size-lg"
                              v-bind:class="`text-${item.type}`"
                          >
                        {{ item.alt }}
                      </span>
                        </div>
                        <!--end: Item -->
                      </template>
                    </div>
                    <!--end::Item-->
                  </template>
                </div>
              </div>
            </b-tab>

            <b-tab>
              <div class="scroll pr-7 mr-n7" id="kt_quick_panel_settings">
                <form class="form">
                  <!--begin::Section-->
                  <div>
                    <h5 class="font-weight-bold mb-3">Customer Care</h5>
                    <div class="form-group mb-0 row">
                      <label class="col-8 col-form-label"
                      >Enable Notifications:</label
                      >
                      <div class="col-4 text-right">
                        <span class="switch switch-success switch-sm">
                          <label>
                            <input type="checkbox" checked="checked" name=""/>
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="form-group mb-0 row">
                      <label class="col-8 col-form-label"
                      >Enable Case Tracking:</label
                      >
                      <div class="col-4 text-right">
                        <span class="switch switch-success switch-sm">
                          <label>
                            <input
                                type="checkbox"
                                name="quick_panel_notifications_2"
                            />
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="form-group mb-0 row">
                      <label class="col-8 col-form-label"
                      >Support Portal:</label
                      >
                      <div class="col-4 text-right">
                        <span class="switch switch-success switch-sm">
                          <label>
                            <input type="checkbox" checked="checked" name=""/>
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!--end::Section-->

                  <div class="separator separator-dashed my-6"></div>

                  <!--begin::Section-->
                  <div class="pt-2">
                    <h5 class="font-weight-bold mb-3">Reports</h5>
                    <div class="form-group mb-0 row">
                      <label class="col-8 col-form-label"
                      >Generate Reports:</label
                      >
                      <div class="col-4 text-right">
                        <span class="switch switch-sm switch-danger">
                          <label>
                            <input type="checkbox" checked="checked" name=""/>
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="form-group mb-0 row">
                      <label class="col-8 col-form-label"
                      >Enable Report Export:</label
                      >
                      <div class="col-4 text-right">
                        <span class="switch switch-sm switch-danger">
                          <label>
                            <input type="checkbox" name=""/>
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="form-group mb-0 row">
                      <label class="col-8 col-form-label"
                      >Allow Data Collection:</label
                      >
                      <div class="col-4 text-right">
                        <span class="switch switch-sm switch-danger">
                          <label>
                            <input type="checkbox" checked="checked" name=""/>
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!--end::Section-->

                  <div class="separator separator-dashed my-6"></div>

                  <!--begin::Section-->
                  <div class="pt-2">
                    <h5 class="font-weight-bold mb-3">Memebers</h5>
                    <div class="form-group mb-0 row">
                      <label class="col-8 col-form-label"
                      >Enable Member singup:</label
                      >
                      <div class="col-4 text-right">
                        <span class="switch switch-sm switch-primary">
                          <label>
                            <input type="checkbox" checked="checked" name=""/>
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="form-group mb-0 row">
                      <label class="col-8 col-form-label"
                      >Allow User Feedbacks:</label
                      >
                      <div class="col-4 text-right">
                        <span class="switch switch-sm switch-primary">
                          <label>
                            <input type="checkbox" name=""/>
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="form-group mb-0 row">
                      <label class="col-8 col-form-label"
                      >Enable Customer Portal:</label
                      >
                      <div class="col-4 text-right">
                        <span class="switch switch-sm switch-primary">
                          <label>
                            <input type="checkbox" checked="checked" name=""/>
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!--end::Section-->
                </form>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    <!-- end::Quick Panel -->
  </div>
</template>

<script>
// import KTLayoutQuickPanel from "@/assets/js/layout/extended/quick-panel.js";
import KTLayoutQuickPanel from "../../../../assets/js/layout/extended/quick-panel";
import axios from 'axios';

export default {
  name: "KTQuickPanel",
  data() {
    return {
      tabIndex: 0,
      list1: [
        {
          title: "Top Authors",
          desc: "Most Successful Fellas",
          alt: "+82$",
          svg: "/media/svg/misc/006-plurk.svg"
        },
        {
          title: "Popular Authors",
          desc: "Most Successful Fellas",
          alt: "+280$",
          svg: "/media/svg/misc/006-plurk.svg"
        },
        {
          title: "New Users",
          desc: "Most Successful Fellas",
          alt: "+4500$",
          svg: "/media/svg/misc/015-telegram.svg"
        },
        {
          title: "Active Customers",
          desc: "Most Successful Fellas",
          alt: "+4500$",
          svg: "/media/svg/misc/003-puzzle.svg"
        }
      ],
      list2: [
        {
          title: "Another purpose persuade",
          desc: "Due in 2 Days",
          alt: "+28%",
          svg: "/media/svg/icons/Home/Library.svg",
          type: "warning"
        },
        {
          title: "Would be to people",
          desc: "Due in 2 Days",
          alt: "+50%",
          svg: "/media/svg/icons/Communication/Write.svg",
          type: "success"
        },
        {
          title: "Purpose would be to persuade",
          desc: "Due in 2 Days",
          alt: "-27%",
          svg: "/media/svg/icons/Communication/Group-chat.svg",
          type: "danger"
        },
        {
          title: "The best product",
          desc: "Due in 2 Days",
          alt: "+8%",
          svg: "/media/svg/icons/General/Attachment2.svg",
          type: "info"
        }
      ],
      list3: [
        {
          title: "5 new user generated report",
          desc: "Reports based on sales",
          icon: "flaticon-bell text-success"
        },
        {
          title: "2 new items submited",
          desc: "by Grog John",
          icon: "flaticon2-box text-danger"
        },
        {
          title: "79 PSD files generated",
          desc: "Reports based on sales",
          icon: "flaticon-psd text-primary"
        },
        {
          title: "$2900 worth producucts sold",
          desc: "Total 234 items",
          icon: "flaticon2-supermarket text-warning"
        },
        {
          title: "4.5h-avarage response time",
          desc: "Fostest is Barry",
          icon: "flaticon-paper-plane-1 text-success"
        },
        {
          title: "3 Defence alerts",
          desc: "40% less alerts thar last week",
          icon: "flaticon-safe-shield-protection text-danger"
        },
        {
          title: "Avarage 4 blog posts per author",
          desc: "Most posted 12 time",
          icon: "flaticon-notepad text-primary"
        },
        {
          title: "16 authors joined last week",
          desc: "9 photodrapehrs, 7 designer",
          icon: "flaticon-users-1 text-warning"
        },
        {
          title: "2 new items have been submited",
          desc: "by Grog John",
          icon: "flaticon2-box text-info"
        },
        {
          title: "2.8 GB-total downloads size",
          desc: "Mostly PSD end  AL concepts",
          icon: "flaticon2-download text-success"
        },
        {
          title: "$2900 worth producucts sold",
          desc: "Total 234 items",
          icon: "flaticon2-supermarket text-danger"
        },
        {
          title: "7 new user generated report",
          desc: "Reports based on sales",
          icon: "flaticon-bell text-primary"
        },
        {
          title: "4.5h-avarage response time",
          desc: "Fostest is Barry",
          icon: "flaticon-paper-plane-1 text-success"
        }
      ],
      notifications: [],
    };
  },
  mounted() {
    // Init Quick Offcanvas Panel
    KTLayoutQuickPanel.init(this.$refs["kt_quick_panel"]);
    this.getNotification();
  },
  methods: {
    getNotification() {
      axios.get(`${process.env.VUE_APP_URL}/api/v2/bo-notification/list?api_token=${localStorage.getItem('api_token')}`)
          .then(res => {
            if (res.data.status_code == 400) {
              this.notifications = res.data.data;
            } else {
              this.notifications = res.data.data;
            }

          })
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    }
  }
};
</script>
<style scoped>
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #1b87c9 !important;
}

.disabled-link {
  pointer-events: none;
}
</style>
