<template>
  <!-- begin:: Aside -->
  <div class="aside aside-left d-flex aside-fixed" id="kt_aside" ref="kt_aside">
    <!--begin::Primary-->
    <div class="aside-primary d-flex flex-column align-items-center flex-row-auto theme_oxport_blue">
      <!--begin::Brand-->
      <KTBrand></KTBrand>
      <!--end::Brand-->
      <!--begin::Nav Wrapper-->
      <div
          class="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull ps"
          style="height: 528px; overflow: hidden;">
        <!--begin::Nav-->
        <ul class="nav flex-column" role="tablist">


          <!--begin::Item Dashboard 0 -->
          <router-link
              to="/dashboard"
              class="active_when_click nav-link btn btn-icon btn-clean btn-lg dashboard"
              data-tab="0">
            <li class="nav-item mb-3"
                data-placement="right"
                data-container="body"
                data-boundary="window"
                v-b-tooltip.hover.right="'Dashboard'">

              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
               <i class="fas fa-tachometer-alt"></i>
                <!--end::Svg Icon-->
              </span>

            </li>
          </router-link>


          <!--begin::Item manage users index = 1 -->
          <li class="nav-item mb-3"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Manage users'"
              v-if="checkUsrPermissions('User List')">
            <a href="#"
               class="nav-link btn btn-icon btn-clean btn-lg"
               role="tab"
               v-on:click="setActiveTab"
               data-tab="1">
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
               <i class="fas fa-users-cog"></i>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->

          <!--begin::Item manage users index = 1 -->
          <li class="nav-item mb-3"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Manage product'"
              v-if="checkUsrPermissions('User product list') || checkUsrPermissions('Product list')">
            <a href="#"
               class="nav-link btn btn-icon btn-clean btn-lg"
               role="tab"
               v-on:click="setActiveTab"
               data-tab="2">
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
               <i class="fab fa-product-hunt"></i>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->

          <!--begin::Item manage users index = 3 -->
          <li class="nav-item mb-3"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Manage orko partner'"
              v-if="checkUsrPermissions('institute.list') || checkUsrPermissions('medical test list') || checkUsrPermissions('Medicine generics list\n')">
            <a href="#"
               class="nav-link btn btn-icon btn-clean btn-lg"
               role="tab"
               v-on:click="setActiveTab"
               data-tab="3">
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
               <i class="fas fa-handshake"></i>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->

          <!--begin::Item Bi reports index = 2 -->
          <li class="nav-item mb-3"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Reports'">
            <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                role="tab"
                v-on:click="setActiveTab"
                data-tab="4"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
              <i class="fas fa-file-signature"></i>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>

          
          <li class="nav-item mb-3"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'HVF'">
            <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                role="tab"
                v-on:click="setActiveTab"
                data-tab="16"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <img src="../../../assets/img/hv.png" class="hvf-img"/>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->

          <!--begin::Item Sales reports index = 2 -->
          <li class="nav-item mb-3"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Sales Information'">
            <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                role="tab"
                v-on:click="setActiveTab"
                data-tab="14"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <!--              <i class="fas fa-file-signature"></i>-->
                <!--                <i class="fas fa-analytics"></i>-->
                <i class="fas fa-chart-line"></i>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->

          <!--begin::Item notifications  index = 5 -->
          <li class="nav-item mb-3"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Manage notification'"
              >
            <a href="#"
               class="nav-link btn btn-icon btn-clean btn-lg"
               role="tab"
               v-on:click="setActiveTab"
               data-tab="5">
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
             <i class="fas fa-bell"></i>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->

          <!--begin::Item notifications  index = 5 -->
          <li class="nav-item mb-3"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Message'"
              >
            <a href="#"
               class="nav-link btn btn-icon btn-clean btn-lg"
               role="tab"
               v-on:click="setActiveTab"
               data-tab="15">
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
            <i class="fas fa-sms"></i>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->

          <!--begin::Item prescriptions  index = 4 -->
          <li class="nav-item mb-3"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Manage prescriptions'"
              v-if="checkUsrPermissions('prescription list') || checkUsrPermissions('Prescription wise doctor list') || checkUsrPermissions('document list')">
            <a href="#"
               class="nav-link btn btn-icon btn-clean btn-lg"
               role="tab"
               v-on:click="setActiveTab"
               data-tab="6">
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
             <i class="fas fa-file-prescription"></i>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->

          <!--begin::Item bank  index = 5 -->
          <li class="nav-item mb-3"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Miscellaneous'">
            <a href="#"
               class="nav-link btn btn-icon btn-clean btn-lg"
               role="tab"
               v-on:click="setActiveTab"
               data-tab="7">
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
            <i class="fas fa-university"></i>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->


          <!--begin::Item   index = 6 -->
          <li class="nav-item mb-3"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Payments'"
              v-if="checkUsrPermissions('export withdrawal doctor list') || checkUsrPermissions('withdrawal disburse list') || checkUsrPermissions('withdrawal patient list')">
            <a href="#"
               class="nav-link btn btn-icon btn-clean btn-lg"
               role="tab"
               v-on:click="setActiveTab"
               data-tab="8">
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <i class="fas fa-file-invoice-dollar"></i>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->

          <!--begin::Item  index = 7  -->
          <li class="nav-item mb-3"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'CRM Panel'">
            <a href="#"
               class="nav-link btn btn-icon btn-clean btn-lg"
               role="tab"
               v-on:click="setActiveTab"
               data-tab="9">
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <!--begin::Svg Icon-->
                <i class="fas fa-headset"></i>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->

          <!--begin::Item  index = 8 -->
          <li class="nav-item mb-3"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="' Book appointment'"
              v-if="checkUsrPermissions('All appointment list') || checkUsrPermissions('followup appointment list')">
            <a href="#"
               class="nav-link btn btn-icon btn-clean btn-lg"
               role="tab"
               v-on:click="setActiveTab"
               data-tab="10">
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <!--begin::Svg Icon-->
                <i class="fas fa-calendar-check"></i>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->

          <!--begin::Item medicine  index = 9 -->
          <li class="nav-item mb-3"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="' Medicine '"
             >
            <a href="#"
               class="nav-link btn btn-icon btn-clean btn-lg"
               role="tab"
               v-on:click="setActiveTab"
               data-tab="11">
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <!--begin::Svg Icon-->
                <i class="fas fa-pills"></i>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->

          <!--begin::Item Subscription   index = 10 -->
          <li class="nav-item mb-3"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="' Subscription '"
              v-if="checkUsrPermissions('subscription package list') || checkUsrPermissions('subscription purchased list')">
            <a href="#"
               class="nav-link btn btn-icon btn-clean btn-lg"
               role="tab"
               v-on:click="setActiveTab"
               data-tab="12">
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <!--begin::Svg Icon-->
               <i class="fas fa-box-open"></i>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->


          <!--begin::Item Subscription   index = 10 -->
          <li class="nav-item mb-3"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="' Manage Permissions '"
              v-if="currentUser.user_type == 'executive'">
            <a href="#"
               class="nav-link btn btn-icon btn-clean btn-lg"
               role="tab"
               v-on:click="setActiveTab"
               data-tab="13">
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <!--begin::Svg Icon-->
              <i class="fas fa-users-cog"></i>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->

        </ul>
        <!--end::Nav-->
      </div>
      <!--end::Nav Wrapper-->
      <!--begin::Footer-->
      <div class="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
        <!--begin::Aside Toggle-->
        <span
            class="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
            id="kt_aside_toggle"
            ref="kt_aside_toggle"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Toggle Aside'"
        >
          <i class="ki ki-bold-arrow-back icon-sm"></i>
        </span>
        <!--end::Aside Toggle-->
        <!--begin::Quick Actions-->
        <KTQuickActions></KTQuickActions>
        <!--end::Quick Actions-->
        <!--begin::Quick Panel-->
        <KTQuickPanel></KTQuickPanel>
        <!--end::Quick Panel-->
        <!--begin::User-->
        <KTQuickUser></KTQuickUser>
        <!--end::User-->
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Primary-->
    <!--begin::Secondary-->
    <div class="aside-secondary d-flex flex-row-fluid">
      <!--begin::Workspace-->
      <div class="aside-workspace scroll scroll-push my-2 ps"
           style="height: 824px; overflow: hidden;">
        <!--begin::Tab Content-->
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <!--begin::Tab Pane  Mange user-->
          <b-tab>
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Dashboard
            </h3>
          </b-tab>

          
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              User Manage
            </h3>
            <div class="list list-hover">
              <!--begin::Item-->

              <p class="text-muted mb-0" v-if="checkUsrPermissions('User List')">Doctor</p>
              <router-link to="/doctor-users" v-if="checkUsrPermissions('User List')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-hospital-user"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Doctors
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/unverified/doctors">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-hospital-user"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Waiting for approval Doctors
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/doctor/movement/tracking">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-hospital-user"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Doctor Movement Tracking

                    </span>
                    </div>
                  </div>
                </div>
              </router-link>


              <router-link to="/aide">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-users"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Aide
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/agent" v-if="checkUsrPermissions('User List')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-user-shield"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      GSO
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0" v-if="checkUsrPermissions('User List')">Agent</p>

              <router-link to="/agent-pharmacy" v-if="checkUsrPermissions('User List')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-user-shield"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Agent pharmacy
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0" v-if="checkUsrPermissions('User List')">Sales</p>
              <router-link to="/agent_sales" v-if="checkUsrPermissions('User List')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-user-shield"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Sale
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>


              <p class="text-muted mb-0" v-if="checkUsrPermissions('User List')">Patient</p>
              <router-link to="/patient-users" v-if="checkUsrPermissions('User List')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-procedures"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Patient
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0" v-if="checkUsrPermissions('User List')">Nurses</p>
              <router-link to="/nurses" v-if="checkUsrPermissions('User List')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-user-shield"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Nurse
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0" v-if="checkUsrPermissions('User List')">Cashiers</p>
              <router-link to="/cashiers" v-if="checkUsrPermissions('User List')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-user-shield"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Cashiers
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

            </div>
          </b-tab>
          <!--end::Tab Pane-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Manage product
            </h3>
            <div class="list list-hover">
              <!--begin::Item-->
              <router-link to="/products" v-if="checkUsrPermissions('Product list')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-35 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fab fa-product-hunt"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Products
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>


              <router-link to="/product/users" v-if="checkUsrPermissions('User product list')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-users"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Product users
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

            </div>
          </b-tab>

          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Manage Orko Partner
            </h3>

            <loader-component v-if="is_loading"/>

            <div class="list list-hover" v-else>
              <!--begin::Item-->
              <router-link to="/manage-diagnostic" v-if="checkUsrPermissions('medical test list')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-35 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-stethoscope"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Manage diagnostic
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/pharmaceuticals" v-if="checkUsrPermissions('Medicine generics list')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-35 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-stethoscope"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Pharmaceuticals
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>


              <router-link :to="`/organizations/report`" v-if="checkUsrPermissions('institute.list')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-35 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-stethoscope"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Organization reports
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

            </div>
          </b-tab>

          <!--begin::Tab Pane Bi reports-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <!--begin::Form-->
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Reports
            </h3>
            <!--begin::List-->
            <div class="list list-hover">

              <router-link to="/general-bi-overview" v-if="checkUsrPermissions('system BI analysis')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      General overview report
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <!--              -->
              <p class="text-muted mb-0">Center reports</p>

              <router-link to="/new/center/report">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                     <i class="fas fa-tachometer-alt"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     OCC New Reports
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/report/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                     <i class="fas fa-tachometer-alt"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     OCC Old Reports
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

             

              <!-- <a href="/occ/call/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                     <i class="fas fa-tachometer-alt"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Call reports
                    </span>
                    </div>
                  </div>
                </div>
              </a> -->

              <a href="/app/activity/reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                     <i class="fas fa-tachometer-alt"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      In app activity report
                    </span>
                    </div>
                  </div>
                </div>
              </a>

              <router-link to="/occ/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                     <i class="fas fa-tachometer-alt"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     OCC  targets
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/team/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                     <i class="fas fa-tachometer-alt"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Team targets
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/call/center/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                     <i class="fas fa-tachometer-alt"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Call center targets
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/service/feedbacks" >
                  <div class="list-item hoverable p-1 p-lg-2 mb-1">
                    <div class="d-flex align-items-center">
                      <!--begin::Symbol-->
                      <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                      </div>
                      <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Service Feedbacks
                      </span>
                      </div>
                    </div>
                  </div>
                </router-link>


              <router-link to="/center-wise-dashboard">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                     <i class="fas fa-tachometer-alt"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Dashboard
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/report/invoice/list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                     <i class="fas fa-file-invoice"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Invoice List
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/agent-wise-patient">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                     <i class="fas fa-user-injured"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Patient onboard
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/employee-referral-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                     <i class="fas fa-users"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Employee Referral List
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <!--              <router-link to="/service-wise-report">-->
              <!--                <div class="list-item hoverable p-1 p-lg-2 mb-1">-->
              <!--                  <div class="d-flex align-items-center">-->
              <!--                    <div class="symbol symbol-40 symbol-light mr-4">-->
              <!--                    <span class="symbol-label">-->
              <!--                     <i class="fas fa-tachometer-alt"></i>-->
              <!--                    </span>-->
              <!--                    </div>-->
              <!--                    <div class="d-flex flex-column flex-grow-1 mr-2">-->
              <!--                    <span class="text-dark-75 font-size-h6 mb-0">-->
              <!--                     Service wise reports-->
              <!--                    </span>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </router-link>-->
              <router-link to="/center-wise-appointment">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                     <i class="fas fa-calendar-check"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Appointments history
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/centre-wise-patient-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Center wise patient list
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/center-wise-prescription-list"
              >
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Center wise prescription list
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/center-wise-income" v-if="checkUsrPermissions('orko center wise income')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Center wise income
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/report/doctor-referral/list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                     <i class="fas fa-users"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Doctor's Referral List
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Area wise reports</p>

              <router-link to="/area-wise-doctor" v-if="checkUsrPermissions('area wise doctor statistics')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                    Area wise doctor
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>


              <p class="text-muted mb-0">Financial reports</p>

              <router-link to="/transaction-report" v-if="checkUsrPermissions('system transaction report')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Transaction report
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/financial-report" v-if="checkUsrPermissions('system financial report')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Financial report
                    </span>
                      <span class="font-size-8">prescription</span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/financial-statement" v-if="checkUsrPermissions('system financial statement')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Financial statement
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Others reports</p>


              <router-link to="/feedback-analysis-report" v-if="checkUsrPermissions('feedback analysis')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Feedback analysis report
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/locality-analysis-report" v-if="checkUsrPermissions('locality list with filter')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Locality analysis
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/nature-of-service-report" v-if="checkUsrPermissions('Medical service list')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Nature of service report
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/connection-request-pending-list" v-if="checkUsrPermissions('connection pending list')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Connection request pending list
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

            </div>
            <!--end::List-->
          </b-tab>
          <!--end::Tab Pane-->


          <!--begin::Tab Pane ::  Manage notification -->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <!--begin::Form-->
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Manage notification
            </h3>

            <div class="list list-hover">

              <router-link to="/notification-type" v-if="checkUsrPermissions('notification type list')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Notification type
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/notification-template" v-if="checkUsrPermissions('notification template list')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Notification template
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>


              <router-link to="/notification-send" v-if="checkUsrPermissions('notification sends to user')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Push notification
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/notification-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Notification List
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/custom-notification-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Notifications
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

            </div>

          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane ::  Manage prescriptions -->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <!--begin::Form-->
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Manage prescriptions
            </h3>


            <!--begin::List-->
            <div class="list list-hover">

              <router-link to="/prescriptions" v-if="checkUsrPermissions('prescription list')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Prescriptions lists
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/doctor-wise-prescription-list"
                           v-if="checkUsrPermissions('Prescription wise doctor list')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Doctor wise prescription count
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/medical-records" v-if="checkUsrPermissions('document list')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                    Medical Records
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

            </div>
            <!--end::Item-->

          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane ::  Manage miscellaneous-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <!--begin::Form-->
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Manage miscellaneous
            </h3>


            <!--begin::List-->
            <div class="list list-hover">
              <!--begin::Item-->

              <p class="text-muted mb-0">Manage OCC</p>
              <router-link to="/manage/services">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Services
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/center/template">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Center templates
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/condition-we-treat">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                    Condition we treat
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/donors">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Donors
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>


              <p class="text-muted mb-0">Manage Blog</p>
              <router-link to="/blogs/category">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Blog Category
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/blogs/posts">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                    Blog Posts
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>


              <p class="text-muted mb-0">Manage Bank</p>
              <router-link to="/bank">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Bank lists
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Manage Institute</p>
              <router-link to="/institute-type">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-school"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                    Institute type
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/institute">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-school"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                    Institutes
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Prescription miscellaneous</p>

              <router-link to="/investigation-service">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-school"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                    Investigation service
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/investigation">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-school"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                    Investigation
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/chief-complaints">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-comment-medical"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Chief Complaints
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/advice">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-comment-medical"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                    Medical Advice
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/medical/examinations">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-comment-medical"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                    Medical Examinations
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/therapy">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-35 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-stethoscope"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                       Therapy
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/diagnosis">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-35 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-stethoscope"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                       Diagnosis
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Doctor miscellaneous</p>

              <router-link to="/nature-of-service">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-school"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                    Medical services
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/orkospecialities">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-school"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                    Orko specialities
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/orko-profession">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-comment-medical"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                    Orko profession
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Patient miscellaneous</p>
              <router-link to="/symptoms">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-school"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                    Symptoms
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/questionaries/group">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-comment-medical"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                    Questionaires group
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/questionaries">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-comment-medical"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                    Questionaires
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Others</p>

              <router-link to="/medicine/generic/advices">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-school"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                    Medicine generic advices
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/chat-bot-query">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-comment-medical"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                    Orko chat bot query
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

            </div>

          </b-tab>
          <!--end::Tab Pane-->


          <!--begin::Tab Pane Manage Nature of service-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <!--begin::Form-->
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Manage payment
            </h3>


            <!--begin::List-->
            <div class="list list-hover">

              <router-link to="/withdrawal/requests" v-if="checkUsrPermissions('export withdrawal doctor list')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-money-check-alt"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Doctor's Account Details
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/withdrawal/disbursed/list" v-if="checkUsrPermissions('withdrawal disburse list')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-money-check-alt"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Disbursed list
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/patient/withdrawal/list" v-if="checkUsrPermissions('withdrawal patient list')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-money-check-alt"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Patient due list
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

            </div>


          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane Manage Nature of service-->
          <b-tab class="p-3 px-lg-7 py-lg-5">

            <h3 class="p-2 p-lg-3 my-1 my-lg-3">Call details</h3>

            <div class="list list-hover">

              <router-link to="/menu/short-cut">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-phone-volume"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Menu Shortcut
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/call-log">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-phone-volume"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Call Log details
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/report/pending/patient/list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-phone-volume"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Pending report patient list
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/crm/social/call/log">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-phone-volume"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Social media call log
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/crm/outbound/calls"
                           v-if="checkUsrPermissions('Agent Call Log list') || checkUsrPermissions('CRM Call Log list')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-phone-volume"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Outbound Calls
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/agent-wise-patient">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                     <i class="fas fa-user-injured"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Patient onboard
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/call/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                     <i class="fas fa-user-injured"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Call reports
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>


              <router-link to="/audit/list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                     <i class="fas fa-user-injured"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Audit List
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/audit/form">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                     <i class="fas fa-user-injured"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Audit reports forms
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

            </div>

          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane Manage bookappointment -->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <!--begin::Form-->
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Book appointment
            </h3>

            <div class="list list-hover">

              <router-link to="/appointment-list" v-if="checkUsrPermissions('All appointment list')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label ">
                      <i class="far fa-calendar-check"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Appointment list
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/book-appointment">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-phone-volume"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Book appointments
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/follow-up-appointment-list" v-if="checkUsrPermissions('followup appointment list')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-procedures"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Appointment followup list
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <!--              <router-link to="/appointment-history">-->
              <!--                <div class="list-item hoverable p-2 p-lg-3 mb-2">-->
              <!--                  <div class="d-flex align-items-center">-->
              <!--                    <div class="symbol symbol-40 symbol-light mr-4">-->
              <!--                    <span class="symbol-label bg-hover-white">-->
              <!--                      <i class="fas fa-history"></i>-->
              <!--                    </span>-->
              <!--                    </div>-->
              <!--                    <div class="d-flex flex-column flex-grow-1 mr-2">-->
              <!--                    <span class="text-dark-75 font-size-h6 mb-0">-->
              <!--                      Appointment History-->
              <!--                    </span>-->
              <!--                    </div>-->
              <!--                  </div>-->

              <!--                </div>-->
              <!--              </router-link>-->

            </div>

          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane manage medicine -->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <!--begin::Form-->
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Manage Medicine
            </h3>
            <!--begin::List-->
            <div class="list list-hover">
              <!--begin::Item-->

              <router-link to="/medicine-company">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-prescription-bottle-alt"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Medicine Company
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/medicine-class">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-prescription-bottle-alt"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Medicine Class
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>


              <router-link to="/medicine-generics" >
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-capsules"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Medicine Generics
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/medicine">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-pills"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Medicine
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/sales/activity-seal">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Sales activity  seal
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

            </div>
            <!--end::Item-->

          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane Manage Subscription-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <!--begin::Form-->
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Manage Subscription
            </h3>


            <!--begin::List-->
            <div class="list list-hover">
              <!--begin::Item-->

              <router-link to="/subscription-offer">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-prescription-bottle-alt"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Subscription offer
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/subscription-package" v-if="checkUsrPermissions('subscription package list')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-prescription-bottle-alt"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Subscription package
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/subscription-feature">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-capsules"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Subscription features
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/subscriber-users" v-if="checkUsrPermissions('subscription purchased list')">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                     <i class="fas fa-users"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Subscriber users
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

            </div>
            <!--end::Item-->

          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Role & Permissions -->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <!--begin::Form-->
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Manage User Role & Permissions
            </h3>


            <!--begin::List-->
            <div class="list list-hover">
              <!--begin::Item-->

              <router-link to="/users">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-35 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-users"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Users
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/roles">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">

                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <!--end::Symbol-->
                    <!--begin::Text-->
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Manage roles
                    </span>
                    </div>

                    <!--begin::End-->
                  </div>
                </div>
              </router-link>

              <router-link to="/manage-permissions">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Manage permissions
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/assign-permissions">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Assign permissions
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/assign-roles">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">

                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <!--end::Symbol-->
                    <!--begin::Text-->
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Assign user by roles
                    </span>
                    </div>

                    <!--begin::End-->
                  </div>

                </div>
              </router-link>


              <router-link to="/level">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">

                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                     <i class="fas fa-layer-group"></i>
                    </span>
                    </div>
                    <!--end::Symbol-->
                    <!--begin::Text-->
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     Manage Level
                    </span>
                    </div>

                    <!--begin::End-->
                  </div>

                </div>
              </router-link>

              <router-link to="/otp/list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">

                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                     <i class="fas fa-layer-group"></i>
                    </span>
                    </div>
                    <!--end::Symbol-->
                    <!--begin::Text-->
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                     OTP List
                    </span>
                    </div>

                    <!--begin::End-->
                  </div>

                </div>
              </router-link>

            </div>
            <!--end::Item-->

          </b-tab>
          <!--end::Tab Pane-->

          <!-- seles menu -->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Sales Information
            </h3>
            <div class="list list-hover">


              <p class="text-muted mb-0">Sales Call's</p>


              <router-link to="/sales/call/log">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Sales calls
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/sales/prescription/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Sales Target- Prescription Wise
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/sales/prescription/target-reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Sales Target- Prescription wise Achievement
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/sales/call/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Sales Target- Doctor Wise
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/sales/call/target-reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Sales Target- Doctor wise Achievement
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Sales Survey</p>
              <router-link to="/sales/dashboard">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Survey Dashboard
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/sales/activity-reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Sales activity survey
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/sales/daily/reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Sales daily reports
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>


              <router-link to="/sales/activity-logs">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Sales activity logs
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/sales/team/log">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Sales team  log
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Pharmacy Survey</p>
              <router-link to="/sales/pharmacy/survey">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Pharmacy surveys
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

            </div>
          </b-tab>

          <!-- seles menu -->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Manage SMS
            </h3>
            <div class="list list-hover">
              <router-link to="/messages">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <i class="fas fa-file"></i>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Message List
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </b-tab>

          <b-tab>
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              HVF&B Reports
            </h3>
            <div class="list list-hover">
              <router-link to="/hvf/retail/reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <img src="../../../assets/img/hv.png" class="hvf-img"/>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                        HVF & B Reports
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/hvf/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label">
                      <img src="../../../assets/img/hv.png" class="hvf-img"/>
                    </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                        HVF & B Target
                    </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/hvf/budget">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-35 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fab fa-product-hunt"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        HVF & B Budget
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/hvf/costs">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-35 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fab fa-product-hunt"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        HVF & B Costs
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </b-tab>


        </b-tabs>
        <!--end::Tab Content-->
        <!--end::Workspace-->
      </div>
      <!--end::Secondary-->
    </div>

  </div>
  <!-- end:: Aside -->
</template>

<style>
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}

.router-link-active .list-item {
  color: #fff !important;
  font-weight: bold;
  font-size: 20px;
  background-color: #f9a91a;
}

/*.router-link-active li {*/
/*  color: #fff !important;*/
/*  font-weight: bold;*/
/*  background-color: #f9a91a;*/
/*  height: calc(1.5em + 1.65rem + 2px);*/
/*  width: calc(1.5em + 1.65rem + 2px);*/
/*  display: inline-flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  padding: 0;*/
/*  font-size: 1.08rem;*/
/*  border-color: white;*/
/*  line-height: 1.5;*/
/*  border-radius: 0.42rem;*/
/*}*/


.router-link-active .list-item .text-dark-75 {
  color: #fff !important;
}

.router-link-active .list-item:hover {

}

.symbol.symbol-light .symbol-label {
  background: #f26f25;
  color: #fff !important;
}

.symbol.symbol-light .symbol-label i {
  color: #fff !important;
}

.font-size-8 {
  font-size: 11px;
}

.symbol.symbol-40 .symbol-label {
  height: 35px !important;
  width: 35px !important;
}
</style>

<script>
import {mapGetters} from "vuex";
import KTLayoutAside from "../../../assets/js/layout/base/aside";
import KTLayoutAsideMenu from "../../../assets/js/layout/base/aside-menu";
import KTLayoutAsideToggle from "../../../assets/js/layout/base/aside-toggle";
import KTBrand from "../brand/Brand.vue";
import KTQuickActions from "../extras/offcanvas/QuickActions.vue";
import KTQuickUser from "../extras/offcanvas/QuickUser.vue";
import KTQuickPanel from "../extras/offcanvas/QuickPanel.vue";

export default {
  name: "KTAside",
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
      tabIndex: 1,
      active: null,
      institutes: '',
      is_loading: false,
    };
  },
  components: {
    KTBrand,
    KTQuickActions,
    KTQuickUser,
    KTQuickPanel
  },
  mounted() {
    this.$nextTick(() => {
      const asideRef = this.$refs["kt_aside"];
      // Init Aside
      KTLayoutAside.init(asideRef);
      asideRef.querySelectorAll("a[class='menu-link']").forEach(item => {
        item.addEventListener("click", () => {
          KTLayoutAside.getOffcanvas().hide();
        });
      });
      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);
      // Init Aside Toggle
      KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
    });
  },
  created() {
    // this.getRoleWisePermission();

    this.$root.$on('call-active-menu', () => {

      var currenttab = localStorage.getItem('tabIndex');
      this.$nextTick(() => {
        this.tabIndex = parseInt(currenttab);
      })


      if (this.tabIndex == 0) {
        var element = document.querySelector(".aside-enabled");
        element.classList.remove("aside-minimize");
      }

    });


  },
  methods: {

    setActiveTab(event) {
      var element = document.querySelector(".aside-enabled");
      element.classList.remove("aside-minimize");
      let target = event.target;
      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }

      if (this.tabIndex == 0) {
        element.classList.remove("aside-minimize");
        this.$router.push('/dashboard');
      }
      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));
      localStorage.setItem('tabIndex', this.tabIndex);
    },


  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    currentUser() {
      return this.$store.getters['auth/user'];
    }

  }
};
</script>

<style>
.hvf-img  {
  height: 25px;
  border-radius: 7px;
  background: #fff;
}
</style>