<template>
  <!--begin::Brand-->
  <div
    class="aside-brand d-flex flex-column align-items-center flex-column-auto py-5 py-lg-12"
  >
    <!--begin::Logo-->
    <router-link to="#">
      <img :src="siteLogo()" alt="Logo" class="max-h-30px" />
    </router-link>
    <!--end::Logo-->
  </div>
  <!--end::Brand-->
</template>

<style lang="scss" scoped>
.aside-toggle {
  outline: none;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTBrand",
  mounted() {},
  methods: {
    siteLogo() {

      return process.env.BASE_URL + 'media/logos/footer-logo.png';
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    allowMinimize() {
      return !!this.layoutConfig("aside.self.minimize.toggle");
    }
  }
};
</script>
