<template>
  <!-- begin:: Aside -->
  <div class="aside aside-left d-flex aside-fixed" id="kt_aside" ref="kt_aside">
    <!--begin::Primary-->
    <div
      class="aside-primary d-flex flex-column align-items-center flex-row-auto theme_oxport_blue"
    >
      <!--begin::Brand-->
      <KTBrand></KTBrand>
      <!--end::Brand-->
      <!--begin::Nav Wrapper-->
      <div
        class="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull ps"
        style="height: 528px; overflow: hidden;"
      >
        <!--begin::Nav-->
        <ul class="nav flex-column" role="tablist">
          <!--begin::Item Dashboard 0 -->
          <router-link
            to="/new/service/report"
            class="active_when_click nav-link btn btn-icon btn-clean btn-lg dashboard"
            data-tab="0"
          >
            <li
              class="nav-item mb-3"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Dashboard'"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <i class="fas fa-tachometer-alt"></i>
                <!--end::Svg Icon-->
              </span>
            </li>
          </router-link>

          <!--begin::Item manage users index = 1 -->
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Orko Report'"
          >
            <a
              href="#"
              class="nav-link btn btn-icon btn-clean btn-lg"
              role="tab"
              v-on:click="setActiveTab"
              data-tab="4"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <img
                  :src="require('@/assets/img/favicon.png')"
                  alt=""
                  class="hvf-img"
                />
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->

          <!--begin::Item manage users index = 1 -->
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'HVF & B Report'"
          >
            <a
              href="#"
              class="nav-link btn btn-icon btn-clean btn-lg"
              role="tab"
              v-on:click="setActiveTab"
              data-tab="16"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <img src="../../../assets/img/hv-logo.svg" class="hvf-img" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->

          <!--begin::Item manage users index = 3 -->
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'HV Tea Company'"
          >
            <a
              href="#"
              class="nav-link btn btn-icon btn-clean btn-lg"
              role="tab"
              v-on:click="setActiveTab"
              data-tab="17"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <img src="../../../assets/img/hv-logo.svg" class="hvf-img" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->
        </ul>
        <!--end::Nav-->
      </div>
      <!--end::Nav Wrapper-->
      <!--begin::Footer-->
      <div
        class="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10"
      >
        <!--begin::Aside Toggle-->
        <span
          class="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
          id="kt_aside_toggle"
          ref="kt_aside_toggle"
          data-placement="right"
          data-container="body"
          data-boundary="window"
          v-b-tooltip.hover.right="'Toggle Aside'"
        >
          <i class="ki ki-bold-arrow-back icon-sm"></i>
        </span>
        <!--end::Aside Toggle-->
        <!--begin::Quick Actions-->
        <KTQuickActions></KTQuickActions>
        <!--end::Quick Actions-->
        <!--begin::Quick Panel-->
        <KTQuickPanel></KTQuickPanel>
        <!--end::Quick Panel-->
        <!--begin::User-->
        <KTQuickUser></KTQuickUser>
        <!--end::User-->
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Primary-->
    <!--begin::Secondary-->
    <div class="aside-secondary d-flex flex-row-fluid">
      <!--begin::Workspace-->
      <div
        class="aside-workspace scroll scroll-push my-2 ps"
        style="height: 824px; overflow: hidden;"
      >
        <!--begin::Tab Content-->
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <!--begin::Tab Pane  Mange user-->
          <b-tab>
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Dashboard
            </h3>
          </b-tab>

          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Orko Report
            </h3>
            <div class="list list-hover">
              <!--begin::Item-->

              <p class="text-muted mb-0">Care center</p>
              <router-link to="/new/service/report">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report New
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/report/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report Old
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">CRM</p>
              <router-link to="/occ/call/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        CRM Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">App Engaged</p>
              <router-link to="/app/activity/reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        In App Activity Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/custom-notification-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Notification list
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/messages">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        SMS
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Others</p>
              <router-link to="/doctor-wise-prescription-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor wise prescription count
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/call/center/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Call target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/service/feedbacks">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Service Feedbacks
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/report/doctor-referral/list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-users"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor's Referral List
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </b-tab>

          <!--end::Tab Pane-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              HVF & B Report
            </h3>
            <div class="list list-hover">
              <!--begin::Item-->
              <router-link to="/hvf/retail/reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-35 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fab fa-product-hunt"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        HVF & B Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/hvf/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-35 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fab fa-product-hunt"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        HVF & B Target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/hvf/budget">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-35 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fab fa-product-hunt"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        HVF & B Budget
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/hvf/costs">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-35 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fab fa-product-hunt"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        HVF & B Costs
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </b-tab>

          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              HV Tea Company
            </h3>

            <loader-component v-if="is_loading" />

            <div class="list list-hover" v-else>
              <!--begin::Item-->
              <!-- <router-link to="/manage-diagnostic" v-if="checkUsrPermissions('medical test list')">
                  <div class="list-item hoverable p-1 p-lg-2 mb-1">
                    <div class="d-flex align-items-center">
                      
                      <div class="symbol symbol-35 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-stethoscope"></i>
                      </span>
                      </div>
                      <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Manage diagnostic
                      </span>
                      </div>
                    </div>
                  </div>
                </router-link> -->
            </div>
          </b-tab>

          <!--begin::Tab Pane Bi reports-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Orko Report
            </h3>
            <div class="list list-hover">
              <!--begin::Item-->

              <p class="text-muted mb-0">Care center</p>
              <router-link to="/new/service/report">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report New
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/report/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report Old
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">CRM</p>
              <router-link to="/occ/call/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        CRM Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">App Engaged</p>
              <router-link to="/app/activity/reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        In App Activity Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/custom-notification-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Notification list
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/messages">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        SMS
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Others</p>
              <router-link to="/doctor-wise-prescription-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor wise prescription count
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/call/center/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Call target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/service/feedbacks">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Service Feedbacks
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/report/doctor-referral/list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-users"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor's Referral List
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane ::  Manage notification -->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Orko Report
            </h3>
            <div class="list list-hover">
              <!--begin::Item-->

              <p class="text-muted mb-0">Care center</p>
              <router-link to="/new/service/report">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report New
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/report/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report Old
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">CRM</p>
              <router-link to="/occ/call/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        CRM Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">App Engaged</p>
              <router-link to="/app/activity/reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        In App Activity Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/custom-notification-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Notification list
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/messages">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        SMS
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Others</p>
              <router-link to="/doctor-wise-prescription-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor wise prescription count
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/call/center/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Call target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/service/feedbacks">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Service Feedbacks
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/report/doctor-referral/list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-users"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor's Referral List
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane ::  Manage prescriptions -->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Orko Report
            </h3>
            <div class="list list-hover">
              <!--begin::Item-->

              <p class="text-muted mb-0">Care center</p>
              <router-link to="/new/service/report">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report New
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/report/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report Old
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">CRM</p>
              <router-link to="/occ/call/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        CRM Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">App Engaged</p>
              <router-link to="/app/activity/reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        In App Activity Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/custom-notification-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Notification list
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/messages">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        SMS
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Others</p>
              <router-link to="/doctor-wise-prescription-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor wise prescription count
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/call/center/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Call target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/service/feedbacks">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Service Feedbacks
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/report/doctor-referral/list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-users"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor's Referral List
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane ::  Manage miscellaneous-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Orko Report
            </h3>
            <div class="list list-hover">
              <!--begin::Item-->

              <p class="text-muted mb-0">Care center</p>
              <router-link to="/new/service/report">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report New
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/report/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report Old
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">CRM</p>
              <router-link to="/occ/call/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        CRM Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">App Engaged</p>
              <router-link to="/app/activity/reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        In App Activity Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/custom-notification-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Notification list
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/messages">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        SMS
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Others</p>
              <router-link to="/doctor-wise-prescription-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor wise prescription count
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/call/center/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Call target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/service/feedbacks">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Service Feedbacks
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/report/doctor-referral/list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-users"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor's Referral List
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane Manage Nature of service-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Orko Report
            </h3>
            <div class="list list-hover">
              <!--begin::Item-->

              <p class="text-muted mb-0">Care center</p>
              <router-link to="/new/service/report">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report New
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/report/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report Old
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">CRM</p>
              <router-link to="/occ/call/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        CRM Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">App Engaged</p>
              <router-link to="/app/activity/reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        In App Activity Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/custom-notification-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Notification list
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/messages">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        SMS
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Others</p>
              <router-link to="/doctor-wise-prescription-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor wise prescription count
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/call/center/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Call target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/service/feedbacks">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Service Feedbacks
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/report/doctor-referral/list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-users"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor's Referral List
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane Manage Nature of service-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Orko Report
            </h3>
            <div class="list list-hover">
              <!--begin::Item-->

              <p class="text-muted mb-0">Care center</p>
              <router-link to="/new/service/report">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report New
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/report/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report Old
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">CRM</p>
              <router-link to="/occ/call/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        CRM Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">App Engaged</p>
              <router-link to="/app/activity/reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        In App Activity Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/custom-notification-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Notification list
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/messages">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        SMS
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Others</p>
              <router-link to="/doctor-wise-prescription-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor wise prescription count
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/call/center/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Call target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/service/feedbacks">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Service Feedbacks
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/report/doctor-referral/list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-users"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor's Referral List
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane Manage bookappointment -->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Orko Report
            </h3>
            <div class="list list-hover">
              <!--begin::Item-->

              <p class="text-muted mb-0">Care center</p>
              <router-link to="/new/service/report">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report New
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/report/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report Old
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">CRM</p>
              <router-link to="/occ/call/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        CRM Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">App Engaged</p>
              <router-link to="/app/activity/reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        In App Activity Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/custom-notification-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Notification list
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/messages">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        SMS
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Others</p>
              <router-link to="/doctor-wise-prescription-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor wise prescription count
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/call/center/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Call target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/service/feedbacks">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Service Feedbacks
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/report/doctor-referral/list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-users"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor's Referral List
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane manage medicine -->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Orko Report
            </h3>
            <div class="list list-hover">
              <!--begin::Item-->

              <p class="text-muted mb-0">Care center</p>
              <router-link to="/new/service/report">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report New
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/report/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report Old
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">CRM</p>
              <router-link to="/occ/call/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        CRM Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">App Engaged</p>
              <router-link to="/app/activity/reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        In App Activity Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/custom-notification-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Notification list
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/messages">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        SMS
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Others</p>
              <router-link to="/doctor-wise-prescription-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor wise prescription count
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/call/center/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Call target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/service/feedbacks">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Service Feedbacks
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/report/doctor-referral/list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-users"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor's Referral List
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane Manage Subscription-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Orko Report
            </h3>
            <div class="list list-hover">
              <!--begin::Item-->

              <p class="text-muted mb-0">Care center</p>
              <router-link to="/new/service/report">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report New
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/report/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report Old
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">CRM</p>
              <router-link to="/occ/call/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        CRM Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">App Engaged</p>
              <router-link to="/app/activity/reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        In App Activity Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/custom-notification-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Notification list
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/messages">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        SMS
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Others</p>
              <router-link to="/doctor-wise-prescription-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor wise prescription count
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/call/center/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Call target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/service/feedbacks">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Service Feedbacks
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/report/doctor-referral/list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-users"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor's Referral List
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Role & Permissions -->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Orko Report
            </h3>
            <div class="list list-hover">
              <!--begin::Item-->

              <p class="text-muted mb-0">Care center</p>
              <router-link to="/new/service/report">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report New
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/report/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report Old
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">CRM</p>
              <router-link to="/occ/call/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        CRM Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">App Engaged</p>
              <router-link to="/app/activity/reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        In App Activity Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/custom-notification-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Notification list
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/messages">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        SMS
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Others</p>
              <router-link to="/doctor-wise-prescription-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor wise prescription count
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/call/center/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Call target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/service/feedbacks">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Service Feedbacks
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/report/doctor-referral/list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-users"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor's Referral List
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </b-tab>
          <!--end::Tab Pane-->

          <!-- seles menu -->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Orko Report
            </h3>
            <div class="list list-hover">
              <!--begin::Item-->

              <p class="text-muted mb-0">Care center</p>
              <router-link to="/new/service/report">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report New
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/report/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report Old
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">CRM</p>
              <router-link to="/occ/call/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        CRM Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">App Engaged</p>
              <router-link to="/app/activity/reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        In App Activity Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/custom-notification-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Notification list
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/messages">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        SMS
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Others</p>
              <router-link to="/doctor-wise-prescription-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor wise prescription count
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/call/center/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Call target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/service/feedbacks">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Service Feedbacks
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/report/doctor-referral/list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-users"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor's Referral List
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </b-tab>

          <!-- seles menu -->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              Orko Report
            </h3>
            <div class="list list-hover">
              <!--begin::Item-->

              <p class="text-muted mb-0">Care center</p>
              <router-link to="/new/service/report">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report New
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/report/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC Report Old
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">CRM</p>
              <router-link to="/occ/call/center">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        CRM Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <p class="text-muted mb-0">App Engaged</p>
              <router-link to="/app/activity/reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        In App Activity Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/custom-notification-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Notification list
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/messages">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        SMS
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <p class="text-muted mb-0">Others</p>
              <router-link to="/doctor-wise-prescription-list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor wise prescription count
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/occ/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        OCC target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/call/center/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Call target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/service/feedbacks">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-hospital-user"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Service Feedbacks
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/report/doctor-referral/list">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fas fa-users"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        Doctor's Referral List
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </b-tab>

          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              HVF & B Report
            </h3>
            <div class="list list-hover">
              <!--begin::Item-->
              <router-link to="/hvf/retail/reports">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-35 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fab fa-product-hunt"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        HVF & B Report
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link to="/hvf/target">
                <div class="list-item hoverable p-1 p-lg-2 mb-1">
                  <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-35 symbol-light mr-4">
                      <span class="symbol-label">
                        <i class="fab fa-product-hunt"></i>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <span class="text-dark-75 font-size-h6 mb-0">
                        HVF & B Target
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </b-tab>

          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">
              HV Tea Company
            </h3>
            <div class="list list-hover">
              <!--begin::Item-->
            </div>
          </b-tab>
        </b-tabs>
        <!--end::Tab Content-->
        <!--end::Workspace-->
      </div>
      <!--end::Secondary-->
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<style>
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}

.router-link-active .list-item {
  color: #fff !important;
  font-weight: bold;
  font-size: 20px;
  background-color: #f9a91a;
}

/*.router-link-active li {*/
/*  color: #fff !important;*/
/*  font-weight: bold;*/
/*  background-color: #f9a91a;*/
/*  height: calc(1.5em + 1.65rem + 2px);*/
/*  width: calc(1.5em + 1.65rem + 2px);*/
/*  display: inline-flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  padding: 0;*/
/*  font-size: 1.08rem;*/
/*  border-color: white;*/
/*  line-height: 1.5;*/
/*  border-radius: 0.42rem;*/
/*}*/

.router-link-active .list-item .text-dark-75 {
  color: #fff !important;
}

.router-link-active .list-item:hover {
}

.symbol.symbol-light .symbol-label {
  background: #f26f25;
  color: #fff !important;
}

.symbol.symbol-light .symbol-label i {
  color: #fff !important;
}

.font-size-8 {
  font-size: 11px;
}

.symbol.symbol-40 .symbol-label {
  height: 35px !important;
  width: 35px !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import KTLayoutAside from "../../../assets/js/layout/base/aside";
import KTLayoutAsideMenu from "../../../assets/js/layout/base/aside-menu";
import KTLayoutAsideToggle from "../../../assets/js/layout/base/aside-toggle";
import KTBrand from "../brand/Brand.vue";
import KTQuickActions from "../extras/offcanvas/QuickActions.vue";
import KTQuickUser from "../extras/offcanvas/QuickUser.vue";
import KTQuickPanel from "../extras/offcanvas/QuickPanel.vue";

export default {
  name: "KTAside",
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
      tabIndex: 1,
      active: null,
      institutes: "",
      is_loading: false,
    };
  },
  components: {
    KTBrand,
    KTQuickActions,
    KTQuickUser,
    KTQuickPanel,
  },
  mounted() {
    this.$nextTick(() => {
      const asideRef = this.$refs["kt_aside"];
      // Init Aside
      KTLayoutAside.init(asideRef);
      asideRef.querySelectorAll("a[class='menu-link']").forEach((item) => {
        item.addEventListener("click", () => {
          KTLayoutAside.getOffcanvas().hide();
        });
      });
      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);
      // Init Aside Toggle
      KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
    });
  },
  created() {
    // this.getRoleWisePermission();

    this.$root.$on("call-active-menu", () => {
      var currenttab = localStorage.getItem("tabIndex");
      this.$nextTick(() => {
        this.tabIndex = parseInt(currenttab);
      });

      if (this.tabIndex == 0) {
        var element = document.querySelector(".aside-enabled");
        element.classList.remove("aside-minimize");
      }
    });
  },
  methods: {
    setActiveTab(event) {
      var element = document.querySelector(".aside-enabled");
      element.classList.remove("aside-minimize");
      let target = event.target;
      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }

      if (this.tabIndex == 0) {
        element.classList.remove("aside-minimize");
        this.$router.push("/new/service/report");
      }
      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));
      localStorage.setItem("tabIndex", this.tabIndex);
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    currentUser() {
      return this.$store.getters["auth/user"];
    },
  },
};
</script>

<style>
.hvf-img {
  height: 25px;
  border-radius: 7px;
  background: #fff;
}
</style>
